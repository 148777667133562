/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'folder-symlink': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.798 8.271l-3.182 1.97c-.27.166-.616-.036-.616-.372V9.1s-2.571-.3-4 2.4c.571-4.8 3.143-4.8 4-4.8v-.769c0-.336.346-.538.616-.371l3.182 1.969c.27.166.27.576 0 .742"/><path pid="1" d="M.5 3l.04.87a2 2 0 00-.342 1.311l.637 7A2 2 0 002.826 14h10.348a2 2 0 001.991-1.819l.637-7A2 2 0 0013.81 3H9.828a2 2 0 01-1.414-.586l-.828-.828A2 2 0 006.172 1H2.5a2 2 0 00-2 2m.694 2.09A1 1 0 012.19 4h11.62a1 1 0 01.996 1.09l-.636 7a1 1 0 01-.996.91H2.826a1 1 0 01-.995-.91zM6.172 2a1 1 0 01.707.293L7.586 3H2.19q-.362.002-.683.12L1.5 2.98a1 1 0 011-.98z"/>',
    },
});
